<template>
  <v-form>
    <v-row align-content="center">
      <!-- Input: Correo -->
      <v-col cols="12">
        <ValidationProvider
          vid="email"
          :name="$tc('email', 1)"
          :rules="'required|email'"
          v-slot="{ errors }"
        >
          <v-text-field
            autocomplete="off"
            key="email-input"
            outlined
            :error-messages="errors[0]"
            :label="$tc('email', 1)"
            v-model="smtp.email"
          />
        </ValidationProvider>
      </v-col>

      <!-- Botones -->
      <v-col class="pt-0 pb-2" cols="12">
        <div class="password-lost-buttons">
          <v-btn v-if="mode == 1" @click="$emit('registered')" color="error">
            {{ $t('back') }}
          </v-btn>
          <v-btn v-else @click="mode = 1" color="error">
            {{ $t('back') }}
          </v-btn>
          <v-btn color="success" @click="send()">
            {{ $t('sendonly') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
export default {
  data() {
    return {
      smtp: {
        email: ''
      },
      mode: 1,
      load: false,
      changePassword: false
    }
  },
  methods: {
    send() {
      this.load = true
      this.$api.auth
        .forgot({
          form: this.smtp
        })
        .then((response) => {
          if (response.status == 200) {
            console.log(response.data.message)
            this.$toast.success(response.data.message)
            this.load = false
            setTimeout(function () {
              window.location.reload()
            }, 2000)
          }
        })
        .catch((registered) => {
          this.$toast.error(`${this.$tc('registered', 2)}`)
          this.load = false
        })
    }
  }
}
</script>
<style lang="sass">
.password-lost-buttons
  display: flex
  justify-content: center
  gap: 10px
</style>
